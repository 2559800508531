import './App.css';
import Can from './assets/can.png';
import twitter from './assets/twitter.png';
import discord from './assets/discord.png';
import { Container, Row, Col } from 'react-bootstrap';
import Countdown from './components/Countdown';

function App() {
  return (
    <Container fluid style={{ margin: '0px', padding: '0px', backgroundColor: '#040707' }}>
      <div className="header" />
      <div className="can">
        <img src={Can} style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />
      </div>
      <Row className="about">
        <Col md={{ span: 6, offset: 3 }}>
          <h1 style={{ fontFamily: 'INSANITY', color: 'white', textAlign: 'center', marginBottom: '40px' }}>About</h1>
          <p style={{ color: 'white' }}>
            Everyone journeying through the metaverse needs a CanPanion to stick by their side and help them find their
            way.
          </p>
          <p style={{ color: 'white' }}>
            Forged in the blockchain, we are a collection of 2,777 unique NFT's primed to bring immense value to our
            holders. The longer you keep us around, the more perks we'll add to our friendship.
          </p>
          <p style={{ color: 'white' }}> Join us and find out why this is the biggest opportunity of 2022!</p>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h1
            style={{
              fontFamily: 'INSANITY',
              color: 'white',
              textAlign: 'center',
              marginBottom: '40px',
              marginTop: '40px'
            }}
          >
            Join Us
          </h1>
          <Row>
            <Col md={2} />
            <Col md={4}>
              <a href="https://twitter.com/canpanionnft">
                <img
                  src={twitter}
                  style={{
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'block',
                    marginTop: '20px'
                  }}
                />
              </a>
            </Col>
            <Col md={4}>
              <a href="https://discord.gg/canpanionnft">
                <img
                  src={discord}
                  style={{
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'block',
                    marginTop: '20px'
                  }}
                />
              </a>
            </Col>
            <Col md={2} />
          </Row>
        </Col>
      </Row>
      <Row style={{ backgroundColor: '#0071b8', height: '400px', marginTop: '50px' }}>
        <Col>
          <h1
            style={{
              fontFamily: 'INSANITY',
              color: 'white',
              textAlign: 'center',
              marginBottom: '40px',
              marginTop: '40px'
            }}
          >
            Next Update
          </h1>
          <Countdown dateTo="November 21, 2021 18:00:00 GMT-04:00" numberOfFigures={5} callback={() => {}} />
        </Col>
      </Row>
    </Container>
  );
}

export default App;
